import React from 'react'
import styled, { css } from 'styled-components'

const InputGroup = styled.div`
  position: relative;
  margin-bottom: 24px;
`

const InputLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: top left;
  transition: transform 0.25s ease-out;
  pointer-events: none;

  ${({inputValue}) =>
    String(inputValue).length > 0 &&
    css`
      transform: translateY(-62.5%) scale(0.625);
    `
  }
`

const Input = styled.input`
  width: 100%;
  border: 0;
  margin: 0;
  padding: 0;
  appearance: none;
  border-bottom: 1px solid #CACACA;
  padding-bottom: 8px;
  line-height: 1.5;

  &:focus {
    outline: none;
    border-color: #45C2CA;
  }

  &:focus + label {
    transform: translateY(-62.5%) scale(0.625);
    color: #45C2CA;
  }
`

const TextArea = styled.textarea`
  width: 100%;
  border: 0;
  margin: 0;
  padding: 0;
  appearance: none;
  border-bottom: 1px solid #CACACA;
  padding-bottom: 8px;
  line-height: 1.5;

  &:focus {
    outline: none;
    border-color: #45C2CA;
  }

  &:focus + label {
    transform: translateY(-62.5%) scale(0.625);
    color: #45C2CA;
  }
`

export const StyledInput = ({
  type = 'text', 
  label, 
  name, 
  value = '', 
  onChange, 
  required = false,
  pattern = undefined,
  title = undefined
  }) => (
  <InputGroup>
    <Input 
      type={type} 
      name={name} 
      value={value} 
      onChange={onChange} 
      required={required}
      pattern={pattern}
      title={title}
    />
    <InputLabel htmlFor={name} inputValue={value}>{label}</InputLabel>
  </InputGroup>
)

export const StyledTextarea = ({label, name, value, onChange}) => (
  <InputGroup>
    <TextArea name={name} rows='2' value={value} onChange={onChange} />
    <InputLabel htmlFor={name} inputValue={value}>{label}</InputLabel>
  </InputGroup>
)
