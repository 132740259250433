import React, { Component } from 'react'
import styled from 'styled-components'

import Button from '../layouts/button'
import { StyledInput, StyledTextarea } from '../layouts/input'

const DEFAULT_FORM_STATE = {
  name: '',
  email: '',
  tel: '',
  request: ''
}

const ContactBlock = styled.div`
  position: relative;
  width: 100%;
  max-width: 480px;
  padding: 24px;
  background-color: #FFF;

  @media (min-width: 1024px) {
    padding: 48px;
  }

  @media (min-width: 1200px) {
    margin-right: 72px;
  }
`

const ContactBlockHeading = styled.h1`
  margin-bottom: 12px;
  font-size: 1.5rem;
  line-height: 1.5;
`

const ContactBlockSubHeading = styled.p`
  margin-bottom: 24px;
  font-size: 1.125rem;
  line-height: 1.34;
`

const ContactOverlay = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
  background-color: rgba(255, 255, 255, 0.95);

  @media (min-width: 1024px) {
    padding: 48px;
  }
`

class FormContact extends Component {
  constructor(props) {
    super(props)
    this.state = {
      form: {
        ...DEFAULT_FORM_STATE
      },
      success: false,
      error: false
    }
  }

  handleBotInput = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleNameInput = e => {
    const newForm = {
      ...this.state.form,
      name: e.target.value
    }
    this.setState({form: newForm})
  }

  handleEmailInput = e => {
    const newForm = {
      ...this.state.form,
      email: e.target.value
    }
    this.setState({form: newForm})
  }

  handleTelInput = e => {
    const newForm = {
      ...this.state.form,
      tel: e.target.value
    }
    this.setState({form: newForm})
  }

  handleRequestInput = e => {
    const newForm = {
      ...this.state.form,
      request: e.target.value
    }
    this.setState({form: newForm})
  }

  handleFormReset = _ => {
    this.setState({
      form: {...DEFAULT_FORM_STATE},
      success: false,
      error: false
    })
  }

  handleFormSuccess = _ => {
    this.setState({
      form: {...DEFAULT_FORM_STATE},
      success: true
    })
  }

  handleSubmit = e => {
    e.preventDefault()
    //console.log(this.encode({ 'form-name': 'contact', ...this.state.form }))
    fetch('/contact/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: this.encode({ 
        'form-name': 'contact', 
        ...this.state.form 
      })
    })
      .then(this.handleFormSuccess)
      .catch(_ => this.setState({error: true})) 
  }

  encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&');
  }

  render() {
  
    const { form } = this.state
  
    return(
      <ContactBlock>
        <ContactBlockHeading>
          How may we help you?
        </ContactBlockHeading>
        <ContactBlockSubHeading>
          Tell us your idea in mind, and we will come back to you with a solution.
        </ContactBlockSubHeading>
        <form 
          name='contact'
          method='post'
          action='/contact/'
          data-netlify='true'
          data-netlify-honeypot='bot-field'
          onSubmit={this.handleSubmit}
        >
          <input type='hidden' name='form-name' value='contact' />
          <p hidden>
            <label>
              Don’t fill this out:{" "}
              <input name="bot-field" onChange={this.handleBotChange} />
            </label>
          </p>
          <StyledInput 
            type='text'
            label='Your name *'
            name='name'
            value={form.name}
            onChange={this.handleNameInput}
            required={true}
          />
          <StyledInput 
            type='email'
            label='Your email *'
            name='email'
            value={form.email}
            onChange={this.handleEmailInput}
            required={true}
          />
          <StyledInput 
            type='tel'
            label='Your phone number'
            name='tel'
            value={form.tel}
            onChange={this.handleTelInput}
            pattern={'[0-9\-\(\)\+]{7,15}'}
            title={'Only number 0-9, -, +, ( ,) are allowed. Minimum 7 characters.'}
          />
          <StyledTextarea 
            label='Your request'
            name='request'
            value={form.request}
            onChange={this.handleRequestInput}
          />
          <Button 
            mod='primary' 
            type='submit' 
            as='button' 
          >
            Get in touch
          </Button>
        </form>
        {this.state.success ?
          <ContactOverlay>
            <ContactBlockHeading>
              Thank you!
            </ContactBlockHeading>
            <ContactBlockSubHeading>
              We will get back to you within 48 hours
            </ContactBlockSubHeading>
            {/* TODO: Add click event to span... ugly... */}
            <span onClick={this.handleFormReset} >
              <Button 
                mod='primary' 
                type='button'
                as='button'              
              >
                New request
              </Button>
            </span>
          </ContactOverlay>
        : null}
      </ContactBlock>
    )
  }

}

export default FormContact