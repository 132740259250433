import React from 'react'
// import { graphql } from 'gatsby'

import styled from 'styled-components'

import Layout from '../components/layout'
import SEO from '../components/seo'

import Section from '../layouts/section'
import Wrapper from '../layouts/wrapper'

import FormContact from '../components/form-contact'

import BgCurve from '../svgs/background-curve.svg'
import Illustration from '../svgs/contact-illustration.svg'

const PageSection = styled(Section)`
  position: relative;
  padding-top: 120px;
  padding-bottom: 48px;

  @media (min-width: 1024px) {
    padding-top: 200px;
    padding-bottom: 120px;
  }
`

const PageWrapper = styled(Wrapper)`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`

const BgCurveWrapper = styled.div`
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;

  @media (min-width: 1024px) {
    display: block;
  }
`

const IllustrationWrapper = styled.div`
  display: block;
  margin-bottom: 24px;
  width: 100%;
  max-width: 240px;

  @media (min-width: 768px) {
    margin-bottom: 0;
    margin-right: 48px;
    max-width: 360px;
  }
  
  @media (min-width: 1024px) {
    margin-right: 0;
  }

  @media (min-width: 1200px) {
    max-width: 455px;
  }
`

const ContactPage = ({ location }) => {

  // const {
  //   title,
  //   heroheader,
  //   services
  // } = data.page.meta
  
  return (
    <Layout location={location.pathname}>
      <SEO title="Let us know your idea, and we will offer you a solution | DesignWeb NZ" />
      <PageSection bgGradient>
        <BgCurveWrapper>
          <BgCurve />
        </BgCurveWrapper>
        <PageWrapper>
          <IllustrationWrapper>
            <Illustration />
          </IllustrationWrapper>
          <FormContact />
        </PageWrapper>
      </PageSection>   
    </Layout>
  )
}

export default ContactPage

// export const query = graphql`
//   query ContactPageQuery {
//     page: markdownRemark(frontmatter: {template: {eq: "home"}}) {
//       meta: frontmatter {
//         title
//         heroheader {
//           heading
//           subheading
//         }
//         services {
//           heading
//           excerpt
//           url
//         }
//       }
//     }
//   }
// `;